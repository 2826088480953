import "./LandingHome.css";
import React, { useState } from "react";

function LandingHome() {
  const [introText, setIntroSubmissionMessage] = useState("Request Demo");
  const [footerText, setFooterSubmissionMessage] = useState("Request Demo");
  const handleSubmitIntro = (event) => {
    event.preventDefault();
    // Your form submission logic here
    console.log("Form submitted");
    // You can access form data using event.target
    const formData = new FormData(event.target);
    const email = formData.get("email-intro");
    const name = formData.get("name-intro");
    console.log("Email:", email, "Name:", name);
    fetch("https://api.speaksport.ai/message/send-email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: name,
        email: email,
      }),
    })
      .then((response) => {
        if (response.ok) {
          console.log("Success sending email.");
          setIntroSubmissionMessage("Submitted!");
        } else {
          console.log("Failure sending email.");
          setIntroSubmissionMessage("Failed!");
        }
      })
      .catch((error) => {
        console.error("Error sending Email:", error);
        setIntroSubmissionMessage("Failed!");
        return false;
      });
  };

  const handleSubmitFooter = (event) => {
    event.preventDefault();
    // Your form submission logic here
    console.log("Form submitted");
    // You can access form data using event.target
    const formData = new FormData(event.target);
    const email = formData.get("email-footer");
    const name = formData.get("name-footer");
    console.log("Email:", email, "Name:", name);
    fetch("https://api.speaksport.ai/message/send-email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: name,
        email: email,
      }),
    })
      .then((response) => {
        if (response.ok) {
          console.log("Success sending email.");
          setFooterSubmissionMessage("Submitted!");
        } else {
          console.log("Failure sending email.");
          setFooterSubmissionMessage("Failed!");
        }
      })
      .catch((error) => {
        console.error("Error sending Email:", error);
        setFooterSubmissionMessage("Failed!");

        return false;
      });
  };
  return (
    <div className="LandingHome">
      <section className="intro">
        <div className="overlay">
          <div id="navbar">
            <div id="navbar-title">
              <img src="assets/logo-1.png" alt="SpeakSport Logo" />
              <h2>SpeakSport</h2>
            </div>
            <div className="navbar-links">
              <a href="#how-it-works">How It Works</a>
              <a href="#see-it-yourself">See It Work</a>
            </div>
          </div>
          <div className="center-column" id="intro-center">
            <h1>24/7 AI Voice Tee Time Receptionist</h1>
            <div id="sub-title-holder">
              <h3 id="intro-h2-title">
                Ensure a{" "}
                <span className="olive">
                  seamless tee-time booking experience{" "}
                </span>{" "}
                for your golfers without having to pick up the phone.
              </h3>
            </div>
            <form onSubmit={handleSubmitIntro}>
              <div className="intro-input-fields">
                <input
                  type="email"
                  name="email-intro"
                  placeholder="Enter Your Email"
                  required
                />
                <input
                  type="text"
                  name="name-intro"
                  placeholder="Enter Your Course"
                  required
                />
              </div>
              <button id="intro-submit-button" type="submit">
                {introText}
              </button>
            </form>
          </div>
        </div>
      </section>
      <section className="how-it-works" id="how-it-works">
        <h2 className="secondary-header">How SpeakSport Works</h2>
        <div className="center-column">
          <div id="how-it-works-title-container">
            <h1 id="how-it-works-title" className="roboto-bold">
              Your <span class="dark-green">AI Voice-Tee Time</span> Reservation
              Solution
            </h1>
          </div>
          <div
            id="how-it-works-subtitle-container"
            className="roboto-thin-italic"
          >
            <h3>
              Win back hours of time each day to focus on{" "}
              <span className="olivine">
                {" "}
                delivering quality, personalized experience{" "}
              </span>{" "}
              to your daily fee and member players
            </h3>
          </div>

          <div className="info-blocks-container">
            <div className="info-block">
              <div className="info-block-content">
                <img src="assets/hand-clock.png" alt="Hand Holding Clock" />
                <div className="img-block-descriptions">
                  <h4>Save</h4>
                  <h4>Significant</h4>
                  <h4>Time</h4>
                </div>
              </div>
            </div>
            <div className="info-block">
              <div className="info-block-content">
                <img
                  src="assets/money-spike.png"
                  alt="Graph With Money Spike"
                />
                <div className="img-block-descriptions">
                  <h4>Boost</h4>
                  <h4>Incremental</h4>
                  <h4>Revenue</h4>
                </div>
              </div>
            </div>
            <div className="info-block">
              <div className="info-block-content">
                <img src="assets/person-phone.png" id="phone-image" alt="" />
                <div className="img-block-descriptions">
                  <h4>Improved</h4>
                  <h4>Customer</h4>
                  <h4>Experience</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="see-it-yourself">
        <h2 className="secondary-header">See It For Yourself</h2>
        <div className="center-column">
          <div className="audio-block-container">
            <div className="audio-blocks">
              <div className="content-block">
                <div className="audio-block">
                  <div className="headshot-circle">
                    <img src="./assets/smiling-man-1.png" alt="Smiling man." />
                  </div>
                  <audio className="audio-control" controls>
                    <source
                      src="../assets/tiger-prairie.mp3"
                      type="audio/mpeg"
                    />
                  </audio>
                </div>
                <div className="discription-block">
                  <h3>For First Time Players</h3>
                </div>
              </div>
              <div className="content-block">
                <div className="audio-block">
                  <div className="headshot-circle">
                    <img src="./assets/women-photo.webp" alt="Smiling woman." />
                  </div>
                  <audio className="audio-control" controls>
                    <source src="../assets/jack-valley.mp3" type="audio/mpeg" />
                  </audio>
                </div>
                <div className="discription-block">
                  <h3>For Returning Players</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="demo-signup">
        <h2 className="secondary-header">Request a Demo!</h2>
        <form className="footer-form" onSubmit={handleSubmitFooter}>
          <div className="footer-input-fields">
            <div className="footer-element">
              <input
                type="email"
                name="email-footer"
                placeholder="Enter Your Email"
                required
              />
            </div>
            <div className="footer-element">
              <input
                type="text"
                name="name-footer"
                placeholder="Enter Your Course"
                required
              />
            </div>
            <div className="footer-element">
              <button id="footer-submit-button" type="submit">
                {footerText}
              </button>
            </div>
          </div>
        </form>
        <div id="footer-logo-section">
          <img src="assets/logo-2.png" alt="SpeakSport Logo" id="footer-logo" />
        </div>
      </section>
    </div>
  );
}

export default LandingHome;
